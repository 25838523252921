import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .plyr {
    &.plyr--stopped {
      .plyr__controls {
        display: none;
      }
    }
  }

  .plyr--video {
    .plyr__control:hover {
      background: #0276f1;
    }
  }

  .plyr__control--overlaid {
    background: #0276f1;
    padding: 27px;
  }
`;

function VideoPlayer({ url, poster, ratio, ...props }) {
  const playerWrapper = useRef();
  const player = useRef();

  useEffect(() => {
    player.current = new Plyr(playerWrapper.current, {
      ratio,
    });
    player.current.poster = poster;
  }, []);

  return (
    <Wrapper {...props}>
      <div
        data-plyr-provider="youtube"
        data-plyr-embed-id={url}
        ref={playerWrapper}
      />
    </Wrapper>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  poster: PropTypes.string,
  ratio: PropTypes.string,
};

VideoPlayer.defaultProps = {
  url: '',
  poster: '',
  ratio: '16:9',
};

export default VideoPlayer;
