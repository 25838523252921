import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0276f1;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #015ec0;
  }
`;

function Button({ icon, ...props }) {
  return <Wrapper {...props}>{icon}</Wrapper>;
}

Button.propTypes = {
  icon: PropTypes.node.isRequired,
};

export default Button;
