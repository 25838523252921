import React from 'react';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import worldMap from 'assets/about/world-map.svg';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 224px;
  background: url(${worldMap}) no-repeat center center;
  background-size: contain;

  @media (min-width: ${breakpoints.md}) {
    min-height: 378px;
  }
`;

const Counter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Count = styled.div`
  font-family: Helvetica;
  font-size: 2rem;
  font-weight: 700;
  color: #0276f1;
  letter-spacing: 0;
  text-align: center;
  line-height: 3.4375rem;
`;

function ConsumersImage() {
  return (
    <Wrapper>
      <Counter>
        <Count>1 Milyar+</Count>        
      </Counter>
    </Wrapper>
  );
}

export default ConsumersImage;
