import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const SectionBody = styled.div`
  margin: 3.25rem 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 4.75rem 0;
  }
`;

export default SectionBody;
