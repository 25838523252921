import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Wave } from './Wave';

const Container = styled.div`
  width: 100%;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0;
    height: 50px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const StyledWave = styled(Wave)`
  width: 100vw;
  height: 350px;
`;

const waveStyle = { width: '100vw', height: '350px' };

function WaveAnimation({ className }) {
  return (
    <Container className={className}>
      <StyledWave
        style={waveStyle}
        height={50}
        speed={1}
        color={0x000000}
        scale={5}
      />
    </Container>
  );
}

WaveAnimation.defaultProps = {
  className: null,
};

WaveAnimation.propTypes = {
  className: PropTypes.string,
};

export default WaveAnimation;
