import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link/Pure';
import { FaLinkedinIn } from 'react-icons/fa';
import breakpoints from 'utils/breakpoints';

const LinkedInLink = styled(Link)`
  color: #fff;
  background: #0077b5;
  border: 4px solid #fff;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  box-sizing: border-box;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const Wrapper = styled.div`
  margin: 0 auto 60px;
  font-size: 0.75rem;
  color: #27294f;
  letter-spacing: 0;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    margin: 27px 0 0 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.75rem;

    a {
      color: #0276f1;
      text-decoration: none;
    }
  }

  span {
    font-size: 14px;
    line-height: 22px;
    color: #1c2f45;
    font-weight: bold;
    margin-bottom: 2px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    color: #1c2f45;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    ${LinkedInLink} {
      visibility: hidden;
      opacity: 0;
      transition: visibility, opacity 0.2s linear;
    }

    h5 a {
      color: #1c2f45;
      transition: color 0.2s linear;
    }

    &:hover {
      ${LinkedInLink} {
        opacity: 1;
        visibility: visible;
      }
      h5 a {
        color: #0276f1;
      }
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  position: relative;

  span {
    background: #f4f9ff;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

function TeamMember({ image, name, title, description, linkedIn }) {
  return (
    <Wrapper>
      <ImageWrapper>
        {image}
        <LinkedInLink title={name} target="_blank" to={linkedIn}>
          <FaLinkedinIn />
        </LinkedInLink>
      </ImageWrapper>
      <h5>
        <Link to={linkedIn}>{name}</Link>
      </h5>
      <span>{title}</span>
      <p>{description}</p>
    </Wrapper>
  );
}

TeamMember.propTypes = {
  image: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkedIn: PropTypes.string.isRequired,
};

export default TeamMember;
