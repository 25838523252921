import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'components/Img';
import breakpoints from 'utils/breakpoints';

const StyledImg = styled(Img)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 800px;
  }
`;

function EarthImage() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about/earth-02.png" }) {
        publicURL
      }
    }
  `);

  return <StyledImg {...data.file} />;
}

export default EarthImage;
